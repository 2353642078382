import { pathToRegexp } from 'path-to-regexp';

import type { ManifestMap } from '@xing-com/crate-core-assets';

export const CATCH_ALL_ROUTE = '/*splat';
export const KNOWN_SITES = ['xing', 'lebenslauf', 'xing-login'] as const;
export type Site = (typeof KNOWN_SITES)[number];

export type RouteInfo = {
  route: string;
  owner: string;
  site: Site;
  xinglet: string;
};

function sanitizeRoute(route: string): [string, Site] {
  const parts = route.match(/^site:(.*?)\/(.*)$/)?.slice(1) ?? ['xing', route];
  const [site] = parts;
  let [, path] = parts;

  if (!path.startsWith('/')) path = `/${path}`;
  if (path.endsWith('/*')) path = path.replace(/\/\*$/, CATCH_ALL_ROUTE);

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return [path, site as Site];
}

export function getPath(url: string): string {
  return url.split(/[?#]/, 1)[0];
}

export function extractRoutes(
  manifestMap: ManifestMap,
  site?: Site
): RouteInfo[] {
  const routes = Object.entries(manifestMap).flatMap(([xinglet, manifest]) => {
    const { contributes = {}, owner = 'unknown' } = manifest.metadata;
    const { route, routes } = contributes;

    return [...(route ? [route] : []), ...(routes ?? [])].flatMap(
      (route): RouteInfo[] => {
        const [path, routeSite] = sanitizeRoute(route);

        if (site && routeSite !== site) return [];

        return [{ route: path, owner, site: routeSite, xinglet }];
      }
    );
  });

  // A negative value indicates that a should come before b.
  // A positive value indicates that a should come after b.
  return routes.sort((a, b) => {
    const partsA = a.route.split('/').slice(1);
    const partsB = b.route.split('/').slice(1);
    const max = Math.max(partsA.length, partsB.length);

    for (let i = 0; i < max; i++) {
      const partA = partsA[i];
      const partB = partsB[i];

      if (partA === partB) continue;

      // `undefined` comes after not `undefined`
      if (partA === undefined && partB !== undefined) return +1;
      if (partA !== undefined && partB === undefined) return -1;

      // '' comes after not ''
      if (partA === '' && partB !== '') return +1;
      if (partA !== '' && partB === '') return -1;

      const charA = partA[0];
      const charB = partB[0];

      // '*' comes after not '*'
      if (charA === '*' && charB !== '*') return +1;
      if (charA !== '*' && charB === '*') return -1;

      // ':' comes after not ':'
      if (charA === ':' && charB !== ':') return +1;
      if (charA !== ':' && charB === ':') return -1;

      return partA.localeCompare(partB);
    }

    return a.route.localeCompare(b.route);
  });
}

export type RouteMatcher = (url: string) => RouteInfo;

export function createRouteMatcher(
  manifestMap: ManifestMap,
  site: Site | undefined
): RouteMatcher {
  const routes = extractRoutes(manifestMap, site).map(
    ({ route, owner, site, xinglet }) => {
      const { regexp } = pathToRegexp(route);

      return {
        route,
        owner,
        regexp,
        site,
        xinglet,
      };
    }
  );

  return (url) => {
    const entry = routes.find(({ regexp }) => regexp.test(getPath(url)));
    const {
      route = CATCH_ALL_ROUTE,
      owner = 'unknown',
      site = 'xing',
      xinglet = 'unknown',
    } = entry ?? {};

    return { route, owner, site, xinglet };
  };
}

export function detectSite({
  entryPoint,
  manifestScope,
}: {
  entryPoint: string;
  manifestScope: string;
}): Site | undefined {
  if (manifestScope !== 'xing') return undefined;
  if (entryPoint === '@xing-com/crate-communication-root') return 'xing';
  if (entryPoint === '@xing-com/crate-login-root') return 'xing-login';
  if (
    entryPoint === '@xing-com/crate-lebenslauf-root' ||
    entryPoint === '@xing-com/crate-lebenslauf-anschreiben'
  ) {
    return 'lebenslauf';
  }

  return undefined;
}
